.page-index {
  align-self: right;
  height: 80px;
  position: relative;

  .paging {
    position: absolute;
    padding: 15px;

    button {
      background: rgb(255, 255, 255);
      margin: 0px 4px 0px 4px;
      border-radius: 3px;
      border: none;
      box-shadow: rgba(30, 34, 42, 0.285) 0px 1px 12px 0px;
      :hover {
        color: khaki;
      }
    }

    .btn-common {
      height: 30px;
      width: 60px;
      line-height: 1;
      padding: 10px;
      font-weight: 600;
    }

    .btn-common:hover {
      background: rgba(97, 192, 207, 0.625);
      transition: 0.3s;
      color: whitesmoke;
    }

    .btn-cusor {
      top: 6px;
      position: relative;
      height: 30px;
      width: 30px;
    }

    .state {
      padding: 6px 15px 8px 15px;
      margin: 3px;
      width: 90px !important;
      margin-top: 1px;
      color: brown;
      background: rgb(255, 255, 255);
      box-shadow: rgba(30, 34, 42, 0.197) 0px 3px 14px 0px;
    }

    i {
      float: center;
    }

    label {
      color: blue !important;
      
    }

    label:hover{
      text-decoration: blue !important; 
    }
  }
}
