@import './variables';
.pc-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background: rgb(228, 228, 228);
  // color: rgb(132, 142, 156);
  .container {
    // max-width: 75rem;
    padding: 0 30px;
    flex: 1 1 0%;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;
  }
  .infomation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      margin-right: 7px;
      margin-left: 8px;
    }
    p > a {
      text-decoration: none;
      color: inherit;
      font-family: ProximaNova-Regular;
      margin: 0;
    }
    p > a:hover {
      color: #17b8e0;
      text-decoration: underline;
    }
  }
}
// .footer {
//   width: 100%;
//   line-height: 50px;
//   background: rgb(228, 228, 228);
//   z-index: 10;
//   position: fixed;
//   bottom: 0;
//   // left: 0;
//   .container {
//     display: flex;
//     justify-content: flex-end;
//   }
//   .infomation {
//     a {
//       padding: 0 7px;
//       cursor: pointer;
//     }
//   }
// }
