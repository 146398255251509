.not-found {
  position: absolute;
  top: 15%;
  left: 40%;
  // color: white;
  text-align: center;
}
.exception {
  font-size: 20px;
}
.not-found_img {
  img {
    position: absolute;
    width: 30%;
    // height: 100%;
    top: 25%;
    left: 35%;
  }
}
