.search-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .search-box_out-side {
    width: 350px;
    transition: all 0.3s ease;
    &:hover {
      width: 410px;
    }
  }
}
