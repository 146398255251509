@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=vietnamese");
@font-face {
  font-family: 'ProximaNova-Regular';
  src: url(./../assets/font/ProximaNova-Regular.eot?#iefix);
  src: url(./../assets/font/ProximaNova-Regular.eot?#iefix) format("eot"), url(./../assets/font/ProximaNova-Regular.woff2) format("woff2"), url(./../assets/font/ProximaNova-Regular.woff) format("woff"), url(./../assets/font/ProximaNova-Regular.ttf) format("truetype");
  font-display: swap; }

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url(./../assets/font/ProximaNova-Semibold.eot?#iefix);
  src: url(./../assets/font/ProximaNova-Semibold.eot?#iefix) format("eot"), url(./../assets/font/ProximaNova-Semibold.woff2) format("woff2"), url(./../assets/font/ProximaNova-Semibold.woff) format("woff"), url(./../assets/font/ProximaNova-Semibold.ttf) format("truetype");
  font-display: swap; }

@font-face {
  font-family: 'DINPro-Regular';
  src: url(./../assets/font/DINPro-Regular.eot?#iefix);
  src: url(./../assets/font/DINPro-Regular.eot?#iefix) format("eot"), url(./../assets/font/DINPro-Regular.woff2) format("woff2"), url(./../assets/font/DINPro-Regular.woff) format("woff"), url(./../assets/font/DINPro-Regular.ttf) format("truetype");
  font-display: swap; }

@font-face {
  font-family: 'DINPro-Medium';
  src: url(./../assets/font/DINPro-Medium.eot?#iefix);
  src: url(./../assets/font/DINPro-Medium.eot?#iefix) format("eot"), url(./../assets/font/DINPro-Medium.woff2) format("woff2"), url(./../assets/font/DINPro-Medium.woff) format("woff"), url(./../assets/font/DINPro-Medium.ttf) format("truetype");
  font-display: swap; }

@font-face {
  font-family: 'DINPro-Bold';
  src: url(./../assets/font/DINPro-Bold.eot?#iefix);
  src: url(./../assets/font/DINPro-Bold.eot?#iefix) format("eot"), url(./../assets/font/DINPro-Bold.woff2) format("woff2"), url(./../assets/font/DINPro-Bold.woff) format("woff"), url(./../assets/font/DINPro-Bold.ttf) format("truetype");
  font-display: swap; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body {
  font-family: ProximaNova-Regular, -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  background: #fdfdfd;
  color: #212833; }

a {
  text-decoration: none;
  color: #4a90e2;
  font-family: DINPro-Medium; }
  a:hover {
    text-decoration: underline; }

ul,
li {
  list-style: none; }

input:focus,
button:focus {
  outline: none; }

.container {
  width: 1200px;
  margin: 0 auto; }

.mt_50 {
  margin-top: 50px; }

.mb_30 {
  margin-bottom: 30px; }

.btn-common {
  width: 60px;
  height: 40px; }

.circle-span {
  border-radius: 50%;
  position: relative;
  left: -10px;
  top: 10px;
  width: 6px;
  height: 6px;
  margin: 0px 0px 0px 0px !important; }

.no_data td {
  text-align: center; }

li {
  cursor: pointer; }

pre {
  border: solid rgba(139, 69, 19, 0.258) 1px;
  background-color: rgba(251, 237, 227, 0.36);
  padding: 10px;
  min-width: 600px; }

.col-1 {
  width: 8.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 768px) {
  [class*="col-"] {
    width: 100%; } }

@media (max-width: 950px) and (min-width: 0px) {
  .nf {
    display: none !important; } }

@media only screen and (min-width: 951px) {
  .phone {
    display: none; } }

.header {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: linear-gradient(to right, #04728e 0%, #15b5dd 50%, #04728e 100%); }
  .header .search_box_container {
    position: absolute;
    left: 25%; }
  .header .logo {
    position: relative;
    top: 0px; }
    .header .logo img {
      height: 40px; }
  .header a {
    color: #ffffff;
    transition: color 0.5s; }
  .header a:hover {
    color: #ccc7c7;
    text-decoration-color: #ccc7c7; }

.banner-container {
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(to right, #04728e 0%, #15b5dd 50%, #04728e 100%);
  background-position: left bottom;
  background-repeat: no-repeat;
  padding: 5.5rem 1.25rem 3.125rem;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center; }
  .banner-container h3 {
    color: white;
    font-size: 28px;
    font-weight: 500;
    margin: 0px; }
  .banner-container .searh-box {
    margin: 3.5rem 0 0rem; }

.layout-content {
  max-width: 1200px;
  margin: 0px auto;
  padding: 70px 16px 20px; }
  .layout-content .flexBox {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center; }
  .layout-content h3 {
    color: black;
    font-family: DINPro-Medium;
    font-size: 20px;
    margin: 0px; }
  .layout-content .pc-container .sub-title {
    margin-top: 10px;
    font-size: 12px;
    color: #848e9c; }
    .layout-content .pc-container .sub-title span {
      color: #48515d;
      font-family: DINPro-Bold; }
  .layout-content .detailBlocks .flex-wrap {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1.5rem; }
  .layout-content .detailBlocks .copy_to_add {
    margin-left: 10px;
    cursor: pointer; }
  .layout-content .detailBlocks .flexBox {
    align-items: flex-end; }
  .layout-content .detailBlocks h3 {
    margin: 0px 1.25rem 0px 0px; }
  .layout-content .detailBlocks .id_status {
    font-size: 16px;
    font-family: DINPro-Bold;
    color: #848e9c; }
  .layout-content .transactions .sub-title {
    font-size: 13px; }
  .layout-content .transactions .flex-header {
    align-items: flex-end;
    justify-content: left; }
  .layout-content .transactions h3 {
    margin: 0px 1.25rem 0px 0px; }
  .layout-content .transactions .id_status {
    font-size: 16px;
    font-family: DINPro-Bold;
    color: #848e9c; }

.home-content {
  background-color: #fdfdfd;
  height: 100%;
  flex: 1 1 0%; }

.breadcrumb {
  color: #848e9c;
  font-size: 0.85rem; }

.breadcrumb-item a {
  color: #848e9c;
  text-decoration: none;
  cursor: pointer; }

.breadcrumb-separator {
  display: inline-block;
  padding: 0 0.3125rem; }

.breadcrumb-item:last-child,
.breadcrumb-item:last-child a {
  color: #212833; }

.table_data {
  padding-top: 10px; }
  .table_data table {
    width: 100%;
    display: table;
    border-spacing: 0 5px;
    border-collapse: separate;
    table-layout: fixed;
    font-family: DINPro-Regular; }
  .table_data th {
    padding: 11px 16px;
    text-align: left;
    color: #848e9c; }
  .table_data tbody tr {
    background: #ffffff;
    line-height: 17px;
    margin-bottom: 4px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 10px 0px;
    transition: all 0.3s ease; }
    .table_data tbody tr:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
      transition: all 0.3s ease; }
  .table_data td {
    padding: 10px 16px;
    font-size: 13px; }
    .table_data td.text_overflow {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }

.text_overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.pc-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background: #e4e4e4; }
  .pc-footer .container {
    padding: 0 30px;
    flex: 1 1 0%;
    display: flex;
    justify-content: flex-end; }
  .pc-footer .infomation {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .pc-footer .infomation a {
      margin-right: 7px;
      margin-left: 8px; }
    .pc-footer .infomation p > a {
      text-decoration: none;
      color: inherit;
      font-family: ProximaNova-Regular;
      margin: 0; }
    .pc-footer .infomation p > a:hover {
      color: #17b8e0;
      text-decoration: underline; }

.layout_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  min-width: 992px;
  background-color: #fdfdfd; }

.content {
  flex: 1; }

.chain-value {
  margin-bottom: 40px; }

.bi-spin {
  display: inline-block;
  animation: spin-animation 1s linear infinite;
  line-height: 1; }

@keyframes spin-animation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.blocks_transactions_view {
  margin: 20px 0;
  /* Handle */
  /* Handle on hover */ }
  .blocks_transactions_view .flex {
    display: flex;
    justify-content: space-between; }
  .blocks_transactions_view .blocks_box {
    width: 40%; }
    .blocks_transactions_view .blocks_box .row_blocks {
      margin: 0 15px;
      padding: 12px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(235, 237, 239, 0.5); }
      .blocks_transactions_view .blocks_box .row_blocks .title {
        margin-bottom: 8px; }
      .blocks_transactions_view .blocks_box .row_blocks .block_count {
        font-weight: 500; }
        .blocks_transactions_view .blocks_box .row_blocks .block_count a {
          margin-left: 5px; }
          .blocks_transactions_view .blocks_box .row_blocks .block_count a:hover {
            text-decoration: underline; }
      .blocks_transactions_view .blocks_box .row_blocks .includes {
        font-size: 12px;
        color: #848e9c; }
        .blocks_transactions_view .blocks_box .row_blocks .includes i {
          font-style: normal; }
        .blocks_transactions_view .blocks_box .row_blocks .includes .node {
          color: #212833; }
    .blocks_transactions_view .blocks_box .wrapper-rowbox:hover {
      background-color: rgba(234, 236, 239, 0.2); }
  .blocks_transactions_view .transactions_box {
    width: calc(100% - 40% - 20px);
    margin-left: 20px; }
    .blocks_transactions_view .transactions_box .wrapper-rowbox:hover {
      background-color: rgba(234, 236, 239, 0.2); }
    .blocks_transactions_view .transactions_box .row_transactions {
      margin: 0 15px;
      padding: 12px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(235, 237, 239, 0.5); }
      .blocks_transactions_view .transactions_box .row_transactions .info_tx {
        margin-bottom: 8px; }
        .blocks_transactions_view .transactions_box .row_transactions .info_tx .tx {
          font-weight: 500;
          width: 70%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden; }
      .blocks_transactions_view .transactions_box .row_transactions a {
        margin-left: 5px; }
      .blocks_transactions_view .transactions_box .row_transactions .transactions {
        font-size: 12px;
        color: #848e9c; }
        .blocks_transactions_view .transactions_box .row_transactions .transactions .from_to {
          width: 70%;
          display: flex;
          align-items: center; }
          .blocks_transactions_view .transactions_box .row_transactions .transactions .from_to .from {
            margin-right: 10px;
            width: 50%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
          .blocks_transactions_view .transactions_box .row_transactions .transactions .from_to .to {
            margin-right: 10px;
            width: 50%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
          .blocks_transactions_view .transactions_box .row_transactions .transactions .from_to a {
            width: 70%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden; }
        .blocks_transactions_view .transactions_box .row_transactions .transactions a {
          color: #848e9c;
          font-weight: 500; }
    .blocks_transactions_view .transactions_box .row_transactions:hover {
      background-color: rgba(234, 236, 239, 0.2); }
  .blocks_transactions_view .box_wrap {
    height: 427px;
    overflow-y: scroll;
    background: #ffffff;
    box-shadow: rgba(90, 102, 124, 0.1) 0px 2px 10px 0px; }
    .blocks_transactions_view .box_wrap .seconds_time {
      color: #848e9c; }
  .blocks_transactions_view .box_wrap::-webkit-scrollbar-thumb {
    background: #dfe2e7; }
  .blocks_transactions_view .box_wrap::-webkit-scrollbar-thumb:hover {
    background: #848e9c; }
  .blocks_transactions_view .header_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    line-height: 25px; }
    .blocks_transactions_view .header_top .title {
      font-size: 20px;
      font-weight: normal; }
      .blocks_transactions_view .header_top .title i {
        font-size: 16px;
        margin-right: 10px; }
    .blocks_transactions_view .header_top a {
      color: #848e9c;
      padding: 0 5px; }

.page_info_header {
  padding-top: 28px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-content: center; }
  .page_info_header .wrap .wrap-title {
    color: black;
    font-size: 20px;
    margin: 0px 1.25rem 0px 0px; }
  .page_info_header .wrap .id_code {
    font-size: 16px;
    color: #848e9c;
    margin-left: 15px; }
  .page_info_header .wrap .copy_id {
    margin: 0;
    width: 26px;
    line-height: 24px;
    border: 1px solid #848e9c;
    color: #212833;
    text-align: center;
    font-size: 14px;
    border-radius: 3px;
    margin-left: 10px;
    vertical-align: middle; }

.page_info_content {
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: #5a667c1a 0px 2px 10px 0px;
  border-radius: 3px; }
  .page_info_content .title {
    line-height: 60px;
    padding: 0 20px;
    color: #48515d;
    font-size: 16px;
    border-bottom: 1px solid rgba(235, 237, 239, 0.5); }
    .page_info_content .title .button-contract {
      padding: 20px 30px;
      font-weight: 500;
      margin: 0px 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom: 0px;
      border: solid #dad9d9 1px !important;
      border-bottom: none !important; }
      .page_info_content .title .button-contract:hover {
        background-color: khaki; }
    .page_info_content .title #choose {
      color: white;
      background-color: #6ba7e2; }
    .page_info_content .title #detail {
      margin-right: 40px; }
    .page_info_content .title i {
      margin-right: 10px; }
  .page_info_content .info_body {
    padding: 20px; }
    .page_info_content .info_body .row_detail {
      padding: 8px 0;
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center; }

.row_detail span.label {
  color: #48515d;
  display: block;
  width: 14%;
  font-family: DINPro-Regular; }

.row_detail .text_wrap {
  width: calc(100% - 14%);
  font-weight: 500; }
  .row_detail .text_wrap .fa-clipboard {
    margin-left: 15px; }
  .row_detail .text_wrap .datacode {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.7;
    border: 1px solid #d8d8d8;
    background: #f9f9f9;
    color: #848e9c;
    padding: 5px; }

.success_color {
  color: #00c087;
  font-weight: 600; }

.error_color {
  color: #e03a3e;
  font-weight: 600; }

.TxDirection {
  text-align: center;
  width: 40px; }
  .TxDirection .Out {
    color: #ea0070;
    background: rgba(234, 0, 112, 0.1); }
  .TxDirection .In {
    color: #00c087;
    background: rgba(0, 192, 135, 0.1); }

.breadcrumb li {
  float: left;
  line-height: 24px; }
  .breadcrumb li a {
    padding: 0 5px;
    font-size: 12px;
    color: #848e9c;
    position: relative; }
    .breadcrumb li a:after {
      content: '/';
      position: absolute;
      right: -3px; }

.id_code {
  margin-right: 30px; }

.statusTx {
  color: #212833;
  text-transform: capitalize; }
  .statusTx i {
    font-size: 7px;
    margin-right: 5px;
    vertical-align: middle; }
  .statusTx .deploy {
    color: #d881ee; }
  .statusTx .transfer {
    color: #00c087; }
  .statusTx .call {
    color: #4a90e2; }

.modal-txSigned pre {
  font-size: 12px;
  min-width: 400px;
  max-height: 500px;
  overflow: scroll; }
