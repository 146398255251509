.sub-tilter {
  position: relative;
  left: 10px;
  margin-top: 20px;
  width: auto;
  color: rgb(132, 142, 156);
  word-break: break-all;
  margin-left: 1.125rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

h3 {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-size: 800;
}
.text-center {
  text-align: center !important;
  padding: 0 !important;
  justify-content: center;
  display: flex;
  .btn-success {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00c9a7;
    background: rgba(0, 201, 167, 0.1);
    border-color: transparent;
    border-radius: 50% !important;
    padding: 8px;
    width: 20px;
    height: 20px;
    i {
      font-size: 9px;
    }
  }
  .btn-error {
    color: #de4437;
  }
}
