.tab-contract {
  width: auto;
  height: auto;
  // position: absolute;
  margin-top: 10px !important;
  .row_detail {
    padding: 7px 20px !important;
    margin-bottom: 0 !important;
  }
}

.contract-content {
  // border-top: solid rgb(202, 202, 202) 1px;
  // height: 80pc;
  padding: 8px 5px !important;
  .rc-tabs-nav-container {
    font-size: 15px;
  }
}
.tx-history {
  padding-top: 20px;
}
.viewSource {
  & button {
    margin-left: 30px;
  }
  a {
    text-decoration: none;
  }
}
.src-container {
  .src-header {
    padding: 10px 0;
    font-size: 14px;
    & pre {
      margin-top: 10px;
    }
  }
  .src-content{
    padding: 5px;
  }
}
