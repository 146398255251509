@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=vietnamese);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote,
a, abbr, acronym, address, big, cite,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,pre,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
@font-face {
  font-family: 'ProximaNova-Regular';
  src: url(/static/media/ProximaNova-Regular.19a260ee.eot?#iefix);
  src: url(/static/media/ProximaNova-Regular.19a260ee.eot?#iefix) format("eot"), url(/static/media/ProximaNova-Regular.679b58fe.woff2) format("woff2"), url(/static/media/ProximaNova-Regular.1b82a5de.woff) format("woff"), url(/static/media/ProximaNova-Regular.1b08c963.ttf) format("truetype");
  font-display: swap; }

@font-face {
  font-family: 'ProximaNova-Semibold';
  src: url(/static/media/ProximaNova-Semibold.39d71e8a.eot?#iefix);
  src: url(/static/media/ProximaNova-Semibold.39d71e8a.eot?#iefix) format("eot"), url(/static/media/ProximaNova-Semibold.57d73e9a.woff2) format("woff2"), url(/static/media/ProximaNova-Semibold.17fa6bca.woff) format("woff"), url(/static/media/ProximaNova-Semibold.7b70f153.ttf) format("truetype");
  font-display: swap; }

@font-face {
  font-family: 'DINPro-Regular';
  src: url(/static/media/DINPro-Regular.244eb9bf.eot?#iefix);
  src: url(/static/media/DINPro-Regular.244eb9bf.eot?#iefix) format("eot"), url(/static/media/DINPro-Regular.46a23adb.woff2) format("woff2"), url(/static/media/DINPro-Regular.b16ac6e8.woff) format("woff"), url(/static/media/DINPro-Regular.0c181b61.ttf) format("truetype");
  font-display: swap; }

@font-face {
  font-family: 'DINPro-Medium';
  src: url(/static/media/DINPro-Medium.c274536f.eot?#iefix);
  src: url(/static/media/DINPro-Medium.c274536f.eot?#iefix) format("eot"), url(/static/media/DINPro-Medium.2c1f9c6f.woff2) format("woff2"), url(/static/media/DINPro-Medium.4e71b69c.woff) format("woff"), url(/static/media/DINPro-Medium.a5f15fd1.ttf) format("truetype");
  font-display: swap; }

@font-face {
  font-family: 'DINPro-Bold';
  src: url(/static/media/DINPro-Bold.74bc0a9a.eot?#iefix);
  src: url(/static/media/DINPro-Bold.74bc0a9a.eot?#iefix) format("eot"), url(/static/media/DINPro-Bold.8d5ceefc.woff2) format("woff2"), url(/static/media/DINPro-Bold.daf17d1d.woff) format("woff"), url(/static/media/DINPro-Bold.783f8ebe.ttf) format("truetype");
  font-display: swap; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body {
  font-family: ProximaNova-Regular, -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  font-size: 14px;
  font-weight: normal;
  background: #fdfdfd;
  color: #212833; }

a {
  text-decoration: none;
  color: #4a90e2;
  font-family: DINPro-Medium; }
  a:hover {
    text-decoration: underline; }

ul,
li {
  list-style: none; }

input:focus,
button:focus {
  outline: none; }

.container {
  width: 1200px;
  margin: 0 auto; }

.mt_50 {
  margin-top: 50px; }

.mb_30 {
  margin-bottom: 30px; }

.btn-common {
  width: 60px;
  height: 40px; }

.circle-span {
  border-radius: 50%;
  position: relative;
  left: -10px;
  top: 10px;
  width: 6px;
  height: 6px;
  margin: 0px 0px 0px 0px !important; }

.no_data td {
  text-align: center; }

li {
  cursor: pointer; }

pre {
  border: solid rgba(139, 69, 19, 0.258) 1px;
  background-color: rgba(251, 237, 227, 0.36);
  padding: 10px;
  min-width: 600px; }

.col-1 {
  width: 8.33%; }

.col-2 {
  width: 16.66%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33%; }

.col-5 {
  width: 41.66%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33%; }

.col-8 {
  width: 66.66%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33%; }

.col-11 {
  width: 91.66%; }

.col-12 {
  width: 100%; }

@media only screen and (max-width: 768px) {
  [class*="col-"] {
    width: 100%; } }

@media (max-width: 950px) and (min-width: 0px) {
  .nf {
    display: none !important; } }

@media only screen and (min-width: 951px) {
  .phone {
    display: none; } }

.header {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: linear-gradient(to right, #04728e 0%, #15b5dd 50%, #04728e 100%); }
  .header .search_box_container {
    position: absolute;
    left: 25%; }
  .header .logo {
    position: relative;
    top: 0px; }
    .header .logo img {
      height: 40px; }
  .header a {
    color: #ffffff;
    transition: color 0.5s; }
  .header a:hover {
    color: #ccc7c7;
    -webkit-text-decoration-color: #ccc7c7;
            text-decoration-color: #ccc7c7; }

.banner-container {
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(to right, #04728e 0%, #15b5dd 50%, #04728e 100%);
  background-position: left bottom;
  background-repeat: no-repeat;
  padding: 5.5rem 1.25rem 3.125rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .banner-container h3 {
    color: white;
    font-size: 28px;
    font-weight: 500;
    margin: 0px; }
  .banner-container .searh-box {
    margin: 3.5rem 0 0rem; }

.layout-content {
  max-width: 1200px;
  margin: 0px auto;
  padding: 70px 16px 20px; }
  .layout-content .flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .layout-content h3 {
    color: black;
    font-family: DINPro-Medium;
    font-size: 20px;
    margin: 0px; }
  .layout-content .pc-container .sub-title {
    margin-top: 10px;
    font-size: 12px;
    color: #848e9c; }
    .layout-content .pc-container .sub-title span {
      color: #48515d;
      font-family: DINPro-Bold; }
  .layout-content .detailBlocks .flex-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1.5rem; }
  .layout-content .detailBlocks .copy_to_add {
    margin-left: 10px;
    cursor: pointer; }
  .layout-content .detailBlocks .flexBox {
    align-items: flex-end; }
  .layout-content .detailBlocks h3 {
    margin: 0px 1.25rem 0px 0px; }
  .layout-content .detailBlocks .id_status {
    font-size: 16px;
    font-family: DINPro-Bold;
    color: #848e9c; }
  .layout-content .transactions .sub-title {
    font-size: 13px; }
  .layout-content .transactions .flex-header {
    align-items: flex-end;
    justify-content: left; }
  .layout-content .transactions h3 {
    margin: 0px 1.25rem 0px 0px; }
  .layout-content .transactions .id_status {
    font-size: 16px;
    font-family: DINPro-Bold;
    color: #848e9c; }

.home-content {
  background-color: #fdfdfd;
  height: 100%;
  flex: 1 1; }

.breadcrumb {
  color: #848e9c;
  font-size: 0.85rem; }

.breadcrumb-item a {
  color: #848e9c;
  text-decoration: none;
  cursor: pointer; }

.breadcrumb-separator {
  display: inline-block;
  padding: 0 0.3125rem; }

.breadcrumb-item:last-child,
.breadcrumb-item:last-child a {
  color: #212833; }

.table_data {
  padding-top: 10px; }
  .table_data table {
    width: 100%;
    display: table;
    border-spacing: 0 5px;
    border-collapse: separate;
    table-layout: fixed;
    font-family: DINPro-Regular; }
  .table_data th {
    padding: 11px 16px;
    text-align: left;
    color: #848e9c; }
  .table_data tbody tr {
    background: #ffffff;
    line-height: 17px;
    margin-bottom: 4px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 10px 0px;
    transition: all 0.3s ease; }
    .table_data tbody tr:hover {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
      transition: all 0.3s ease; }
  .table_data td {
    padding: 10px 16px;
    font-size: 13px; }
    .table_data td.text_overflow {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }

.text_overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.pc-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background: #e4e4e4; }
  .pc-footer .container {
    padding: 0 30px;
    flex: 1 1;
    display: flex;
    justify-content: flex-end; }
  .pc-footer .infomation {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .pc-footer .infomation a {
      margin-right: 7px;
      margin-left: 8px; }
    .pc-footer .infomation p > a {
      text-decoration: none;
      color: inherit;
      font-family: ProximaNova-Regular;
      margin: 0; }
    .pc-footer .infomation p > a:hover {
      color: #17b8e0;
      text-decoration: underline; }

.layout_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  min-width: 992px;
  background-color: #fdfdfd; }

.content {
  flex: 1 1; }

.chain-value {
  margin-bottom: 40px; }

.bi-spin {
  display: inline-block;
  -webkit-animation: spin-animation 1s linear infinite;
          animation: spin-animation 1s linear infinite;
  line-height: 1; }

@-webkit-keyframes spin-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.blocks_transactions_view {
  margin: 20px 0;
  /* Handle */
  /* Handle on hover */ }
  .blocks_transactions_view .flex {
    display: flex;
    justify-content: space-between; }
  .blocks_transactions_view .blocks_box {
    width: 40%; }
    .blocks_transactions_view .blocks_box .row_blocks {
      margin: 0 15px;
      padding: 12px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(235, 237, 239, 0.5); }
      .blocks_transactions_view .blocks_box .row_blocks .title {
        margin-bottom: 8px; }
      .blocks_transactions_view .blocks_box .row_blocks .block_count {
        font-weight: 500; }
        .blocks_transactions_view .blocks_box .row_blocks .block_count a {
          margin-left: 5px; }
          .blocks_transactions_view .blocks_box .row_blocks .block_count a:hover {
            text-decoration: underline; }
      .blocks_transactions_view .blocks_box .row_blocks .includes {
        font-size: 12px;
        color: #848e9c; }
        .blocks_transactions_view .blocks_box .row_blocks .includes i {
          font-style: normal; }
        .blocks_transactions_view .blocks_box .row_blocks .includes .node {
          color: #212833; }
    .blocks_transactions_view .blocks_box .wrapper-rowbox:hover {
      background-color: rgba(234, 236, 239, 0.2); }
  .blocks_transactions_view .transactions_box {
    width: calc(100% - 40% - 20px);
    margin-left: 20px; }
    .blocks_transactions_view .transactions_box .wrapper-rowbox:hover {
      background-color: rgba(234, 236, 239, 0.2); }
    .blocks_transactions_view .transactions_box .row_transactions {
      margin: 0 15px;
      padding: 12px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(235, 237, 239, 0.5); }
      .blocks_transactions_view .transactions_box .row_transactions .info_tx {
        margin-bottom: 8px; }
        .blocks_transactions_view .transactions_box .row_transactions .info_tx .tx {
          font-weight: 500;
          width: 70%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden; }
      .blocks_transactions_view .transactions_box .row_transactions a {
        margin-left: 5px; }
      .blocks_transactions_view .transactions_box .row_transactions .transactions {
        font-size: 12px;
        color: #848e9c; }
        .blocks_transactions_view .transactions_box .row_transactions .transactions .from_to {
          width: 70%;
          display: flex;
          align-items: center; }
          .blocks_transactions_view .transactions_box .row_transactions .transactions .from_to .from {
            margin-right: 10px;
            width: 50%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
          .blocks_transactions_view .transactions_box .row_transactions .transactions .from_to .to {
            margin-right: 10px;
            width: 50%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
          .blocks_transactions_view .transactions_box .row_transactions .transactions .from_to a {
            width: 70%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden; }
        .blocks_transactions_view .transactions_box .row_transactions .transactions a {
          color: #848e9c;
          font-weight: 500; }
    .blocks_transactions_view .transactions_box .row_transactions:hover {
      background-color: rgba(234, 236, 239, 0.2); }
  .blocks_transactions_view .box_wrap {
    height: 427px;
    overflow-y: scroll;
    background: #ffffff;
    box-shadow: rgba(90, 102, 124, 0.1) 0px 2px 10px 0px; }
    .blocks_transactions_view .box_wrap .seconds_time {
      color: #848e9c; }
  .blocks_transactions_view .box_wrap::-webkit-scrollbar-thumb {
    background: #dfe2e7; }
  .blocks_transactions_view .box_wrap::-webkit-scrollbar-thumb:hover {
    background: #848e9c; }
  .blocks_transactions_view .header_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    line-height: 25px; }
    .blocks_transactions_view .header_top .title {
      font-size: 20px;
      font-weight: normal; }
      .blocks_transactions_view .header_top .title i {
        font-size: 16px;
        margin-right: 10px; }
    .blocks_transactions_view .header_top a {
      color: #848e9c;
      padding: 0 5px; }

.page_info_header {
  padding-top: 28px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-content: center; }
  .page_info_header .wrap .wrap-title {
    color: black;
    font-size: 20px;
    margin: 0px 1.25rem 0px 0px; }
  .page_info_header .wrap .id_code {
    font-size: 16px;
    color: #848e9c;
    margin-left: 15px; }
  .page_info_header .wrap .copy_id {
    margin: 0;
    width: 26px;
    line-height: 24px;
    border: 1px solid #848e9c;
    color: #212833;
    text-align: center;
    font-size: 14px;
    border-radius: 3px;
    margin-left: 10px;
    vertical-align: middle; }

.page_info_content {
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: #5a667c1a 0px 2px 10px 0px;
  border-radius: 3px; }
  .page_info_content .title {
    line-height: 60px;
    padding: 0 20px;
    color: #48515d;
    font-size: 16px;
    border-bottom: 1px solid rgba(235, 237, 239, 0.5); }
    .page_info_content .title .button-contract {
      padding: 20px 30px;
      font-weight: 500;
      margin: 0px 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom: 0px;
      border: solid #dad9d9 1px !important;
      border-bottom: none !important; }
      .page_info_content .title .button-contract:hover {
        background-color: khaki; }
    .page_info_content .title #choose {
      color: white;
      background-color: #6ba7e2; }
    .page_info_content .title #detail {
      margin-right: 40px; }
    .page_info_content .title i {
      margin-right: 10px; }
  .page_info_content .info_body {
    padding: 20px; }
    .page_info_content .info_body .row_detail {
      padding: 8px 0;
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center; }

.row_detail span.label {
  color: #48515d;
  display: block;
  width: 14%;
  font-family: DINPro-Regular; }

.row_detail .text_wrap {
  width: calc(100% - 14%);
  font-weight: 500; }
  .row_detail .text_wrap .fa-clipboard {
    margin-left: 15px; }
  .row_detail .text_wrap .datacode {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.7;
    border: 1px solid #d8d8d8;
    background: #f9f9f9;
    color: #848e9c;
    padding: 5px; }

.success_color {
  color: #00c087;
  font-weight: 600; }

.error_color {
  color: #e03a3e;
  font-weight: 600; }

.TxDirection {
  text-align: center;
  width: 40px; }
  .TxDirection .Out {
    color: #ea0070;
    background: rgba(234, 0, 112, 0.1); }
  .TxDirection .In {
    color: #00c087;
    background: rgba(0, 192, 135, 0.1); }

.breadcrumb li {
  float: left;
  line-height: 24px; }
  .breadcrumb li a {
    padding: 0 5px;
    font-size: 12px;
    color: #848e9c;
    position: relative; }
    .breadcrumb li a:after {
      content: '/';
      position: absolute;
      right: -3px; }

.id_code {
  margin-right: 30px; }

.statusTx {
  color: #212833;
  text-transform: capitalize; }
  .statusTx i {
    font-size: 7px;
    margin-right: 5px;
    vertical-align: middle; }
  .statusTx .deploy {
    color: #d881ee; }
  .statusTx .transfer {
    color: #00c087; }
  .statusTx .call {
    color: #4a90e2; }

.modal-txSigned pre {
  font-size: 12px;
  min-width: 400px;
  max-height: 500px;
  overflow: scroll; }

.main_nav {
  position: absolute;
  right: 20px; }
  .main_nav .nav_hed {
    display: flex;
    align-items: center; }
    .main_nav .nav_hed .nav_items {
      padding: 0 15px;
      font-weight: 500;
      line-height: 50px; }
      .main_nav .nav_hed .nav_items.sub {
        position: relative;
        cursor: pointer; }
        .main_nav .nav_hed .nav_items.sub:hover .sub_child {
          transition: all 0.5s ease;
          visibility: visible;
          -webkit-transform: translateY(-1px);
                  transform: translateY(-1px);
          height: 90px; }
        .main_nav .nav_hed .nav_items.sub :not(:hover) .sub_child {
          transition: 0.5s; }
        .main_nav .nav_hed .nav_items.sub .sub_child {
          position: absolute;
          border: 1px solid #ebedef;
          padding: 15px;
          left: 1px;
          background: #ffffff;
          width: 110px;
          visibility: hidden;
          height: 0px; }
          .main_nav .nav_hed .nav_items.sub .sub_child .sub_child_items {
            line-height: 30px; }
            .main_nav .nav_hed .nav_items.sub .sub_child .sub_child_items :hover {
              background-color: gray;
              color: white; }
      .main_nav .nav_hed .nav_items a {
        color: #212833; }
        .main_nav .nav_hed .nav_items a:hover {
          transition: all 0.4s ease;
          text-decoration: underline; }

.search-box_out-side {
  width: 470px; }
  .search-box_out-side [tabindex="-1"]:focus {
    outline: none !important; }
  .search-box_out-side input {
    background: rgba(4, 114, 142, 0.2);
    color: #fff;
    font-size: 12px;
    text-overflow: ellipsis; }
    .search-box_out-side input ::-webkit-input-placeholder {
      color: red; }
    .search-box_out-side input ::-ms-input-placeholder {
      color: red; }
    .search-box_out-side input ::placeholder {
      color: red; }
  .search-box_out-side .anticon {
    color: inherit; }
  .search-box_out-side .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 45px; }
  .search-box_out-side .ant-input:hover {
    border-color: #f0b90b;
    border-right-width: 1px !important; }
  .search-box_out-side .ant-input:focus {
    border-color: #f0b90b;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .search-box_out-side .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #f0b90b; }

.search-box {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .search-box .search-box_out-side {
    width: 350px;
    transition: all 0.3s ease; }
    .search-box .search-box_out-side:hover {
      width: 410px; }

.menu-icon {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10;
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: #5a5a5a; }

.background-sidebar {
  position: fixed;
  z-index: 4;
  width: 100pc;
  height: 1000px;
  background-color: rgba(20, 20, 20, 0.496);
  overflow: none; }

.sidebar {
  width: 0px;
  height: 0px;
  position: fixed;
  top: 0px;
  z-index: 5; }

.side-bar_right {
  z-index: 100;
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100%;
  background-color: #1b1b1b;
  transition: 300ms; }
  .side-bar_right .btn-close {
    position: absolute;
    top: 0px;
    height: 8%;
    padding: 10px;
    width: 100%;
    font-size: 30px;
    color: #5f5f80;
    cursor: pointer; }
    .side-bar_right .btn-close i {
      position: absolute;
      right: 10%;
      font-weight: 200; }
  .side-bar_right .content-sidebar {
    width: 100%;
    height: 92%;
    position: absolute;
    top: 8%;
    color: whitesmoke;
    color: white;
    padding: 10px; }
    .side-bar_right .content-sidebar ul li {
      font-weight: 600;
      padding: 20px;
      font-size: 16px;
      font-family: sans-serif; }
      .side-bar_right .content-sidebar ul li i {
        width: 20px;
        height: 20px; }
      .side-bar_right .content-sidebar ul li a {
        color: whitesmoke; }

.blocks_box {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .blocks_box ::-webkit-scrollbar {
    width: 5px;
    border-radius: 2px; }
  .blocks_box ::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .blocks_box ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #888; }
  .blocks_box ::-webkit-scrollbar-thumb:hover {
    background: #555; }
  .blocks_box .view-all {
    padding-top: 30px;
    font-size: 15px;
    cursor: pointer;
    font-family: DINPro-Regular; }

.transactions_box {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .transactions_box ::-webkit-scrollbar {
    width: 5px;
    border-radius: 2px; }
  .transactions_box ::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .transactions_box ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #888; }
  .transactions_box ::-webkit-scrollbar-thumb:hover {
    background: #555555; }
  .transactions_box .view-all {
    padding-top: 30px;
    font-size: 15px;
    cursor: pointer;
    font-family: DINPro-Regular; }

.total-chain {
  display: flex;
  background-color: white;
  box-shadow: rgba(90, 102, 124, 0.1) 0px 2px 10px 0px;
  box-sizing: border-box; }
  .total-chain ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .total-chain li {
    font-size: 12px;
    height: auto;
    padding: 10px 15px 10px 15px; }
  .total-chain .info-stamp {
    font-weight: 800;
    color: #212833;
    font-size: 14px; }

.result_data {
  color: #80c31c !important; }

.page-index {
  align-self: right;
  height: 80px;
  position: relative; }
  .page-index .paging {
    position: absolute;
    padding: 15px; }
    .page-index .paging button {
      background: white;
      margin: 0px 4px 0px 4px;
      border-radius: 3px;
      border: none;
      box-shadow: rgba(30, 34, 42, 0.285) 0px 1px 12px 0px; }
      .page-index .paging button :hover {
        color: khaki; }
    .page-index .paging .btn-common {
      height: 30px;
      width: 60px;
      line-height: 1;
      padding: 10px;
      font-weight: 600; }
    .page-index .paging .btn-common:hover {
      background: rgba(97, 192, 207, 0.625);
      transition: 0.3s;
      color: whitesmoke; }
    .page-index .paging .btn-cusor {
      top: 6px;
      position: relative;
      height: 30px;
      width: 30px; }
    .page-index .paging .state {
      padding: 6px 15px 8px 15px;
      margin: 3px;
      width: 90px !important;
      margin-top: 1px;
      color: brown;
      background: white;
      box-shadow: rgba(30, 34, 42, 0.197) 0px 3px 14px 0px; }
    .page-index .paging i {
      float: center; }
    .page-index .paging label {
      color: blue !important; }
    .page-index .paging label:hover {
      -webkit-text-decoration: blue !important;
              text-decoration: blue !important; }

.sub-tilter {
  position: relative;
  left: 10px;
  margin-top: 20px;
  width: auto;
  color: #848e9c;
  word-break: break-all;
  margin-left: 1.125rem;
  display: flex;
  align-items: center; }

h3 {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
  font-size: 800; }

.text-center {
  text-align: center !important;
  padding: 0 !important;
  justify-content: center;
  display: flex; }
  .text-center .btn-success {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00c9a7;
    background: rgba(0, 201, 167, 0.1);
    border-color: transparent;
    border-radius: 50% !important;
    padding: 8px;
    width: 20px;
    height: 20px; }
    .text-center .btn-success i {
      font-size: 9px; }
  .text-center .btn-error {
    color: #de4437; }

.not-found {
  position: absolute;
  top: 15%;
  left: 40%;
  text-align: center; }

.exception {
  font-size: 20px; }

.not-found_img img {
  position: absolute;
  width: 30%;
  top: 25%;
  left: 35%; }

.container-call-contract {
  position: relative;
  width: 100%;
  height: 650px; }
  .container-call-contract .side-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 25%;
    min-width: 300px; }
    .container-call-contract .side-left .rc-tabs-tab {
      padding: 18px;
      margin-right: 10px; }
    .container-call-contract .side-left .rc-tabs-top {
      border: none; }
    .container-call-contract .side-left .rc-tabs-nav-container {
      font-size: 14px; }
    .container-call-contract .side-left .wrapper-funcs {
      height: 585px;
      overflow-x: hidden; }
      .container-call-contract .side-left .wrapper-funcs ul {
        padding: 1px; }
      .container-call-contract .side-left .wrapper-funcs li {
        display: flex;
        justify-content: space-between;
        margin: 10px;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        align-items: center;
        color: #263147;
        font-weight: 400;
        border-radius: 3px;
        background: #eee; }
      .container-call-contract .side-left .wrapper-funcs li:hover {
        font-weight: 600;
        box-shadow: rgba(223, 226, 231, 0.5) 0px 0px 5px 0px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background: #f5f2f0;
        border-left: 4px solid #15b5dd; }
      .container-call-contract .side-left .wrapper-funcs .on {
        box-shadow: rgba(223, 226, 231, 0.5) 0px 0px 5px 0px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        font-weight: 600;
        background: #f5f2f0;
        border-left: 4px solid #15b5dd; }
      .container-call-contract .side-left .wrapper-funcs .typeFunc {
        font-size: 0.9em;
        width: 50%;
        text-align: center; }
  .container-call-contract .dragbar {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    z-index: 999;
    border-right: 1px solid #f3f3f3; }
  .container-call-contract .side-main {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 74%;
    min-width: 800px;
    overflow: hidden; }
    .container-call-contract .side-main .contrainer-main {
      overflow-x: hidden;
      padding: 10px 50px;
      height: 100%; }
      .container-call-contract .side-main .contrainer-main .connector-wallet {
        padding: 10px 0 20px; }
        .container-call-contract .side-main .contrainer-main .connector-wallet #connector {
          font-size: 12px;
          color: #28a745 !important; }
        .container-call-contract .side-main .contrainer-main .connector-wallet > span {
          margin-right: 15px; }
      .container-call-contract .side-main .contrainer-main .wrapper-func {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 5px;
        margin-bottom: 1rem !important; }
      .container-call-contract .side-main .contrainer-main .info-box {
        line-height: 1;
        font-size: 90%;
        background: #f5f2f0;
        padding: 5px 12px;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
      .container-call-contract .side-main .contrainer-main .active,
      .container-call-contract .side-main .contrainer-main .info-box:hover {
        text-decoration: underline; }
      .container-call-contract .side-main .contrainer-main .func-content {
        padding: 0 18px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        background-color: #f1f1f1; }
      .container-call-contract .side-main .contrainer-main .func-content-shown-by-default {
        max-height: 100%;
        padding: 0 18px;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        background-color: #f1f1f1; }
      .container-call-contract .side-main .contrainer-main .info-box:after {
        content: '\2193';
        /* Unicode character for close */
        font-size: 20px;
        float: right;
        margin-left: 5px; }
      .container-call-contract .side-main .contrainer-main .active:after {
        content: '\2192';
        /* Unicode character for open */
        font-size: 20px; }
      .container-call-contract .side-main .contrainer-main .func-body {
        padding: 1.25rem;
        color: #212529; }
        .container-call-contract .side-main .contrainer-main .func-body .ant-input-affix-wrapper {
          margin-bottom: 1rem; }
        .container-call-contract .side-main .contrainer-main .func-body .ant-btn {
          margin-right: 2rem; }
        .container-call-contract .side-main .contrainer-main .func-body input,
        .container-call-contract .side-main .contrainer-main .func-body textarea {
          min-width: 100%; }
        .container-call-contract .side-main .contrainer-main .func-body textarea {
          margin-bottom: 1rem; }
        .container-call-contract .side-main .contrainer-main .func-body label {
          display: inline-block;
          margin-bottom: 0.5rem; }
        .container-call-contract .side-main .contrainer-main .func-body .myanswer {
          display: block;
          margin-top: 20px;
          line-height: 1.5; }
          .container-call-contract .side-main .contrainer-main .func-body .myanswer pre {
            font-size: 12px; }
          .container-call-contract .side-main .contrainer-main .func-body .myanswer .text-success {
            color: #28a745 !important; }
            .container-call-contract .side-main .contrainer-main .func-body .myanswer .text-success i {
              font-weight: 700; }
          .container-call-contract .side-main .contrainer-main .func-body .myanswer .error {
            color: #dc3545; }

.tab-contract {
  width: auto;
  height: auto;
  margin-top: 10px !important; }
  .tab-contract .row_detail {
    padding: 7px 20px !important;
    margin-bottom: 0 !important; }

.contract-content {
  padding: 8px 5px !important; }
  .contract-content .rc-tabs-nav-container {
    font-size: 15px; }

.tx-history {
  padding-top: 20px; }

.viewSource button {
  margin-left: 30px; }

.viewSource a {
  text-decoration: none; }

.src-container .src-header {
  padding: 10px 0;
  font-size: 14px; }
  .src-container .src-header pre {
    margin-top: 10px; }

.src-container .src-content {
  padding: 5px; }

