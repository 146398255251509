@import "../../../sass/variables";

// .search-box_out-side {
//   position: relative;
//   width: 470px;
//   // height: 40px;
//   // border-radius: 5px;
//   padding: 1px;

//   //search-icon
//   .search-icon {
//     position: fixed;
//     top: 15px;
//     right: 80px;
//     width: 30px;
//     height: 30px;
//     font-size: 20px;
//     color: #5a5a5a;
//   }

//   // input box
//   .search_box_container {
//     position: absolute;
//     top: 4px;
//     left: 0px;
//     display: flex;
//     align-items: center;
//     width: 440px;
//     height: 40px;
//     border-radius: 5px;
//     overflow: hidden;
//     background: $color_white;
//     border: solid rgb(3, 194, 3) 2px;

//     &:hover {
//       transition: all 0.3s ease;
//       box-shadow: -2px 3px 15px 0px $color_blue_lagoon;
//     }

//     .search_input {
//       font-family: ProximaNova-Regular;
//       position: absolute;
//       left: 0px;
//       width: 350px;
//       height: 40px;
//       padding: 0 10px;
//       border: none;
//       font-size: 12px;
//       background: $color_white;
//       &:focus {
//         outline: none;
//       }
//     }

//     .delete-value {
//       position: absolute;
//       right: 50px;
//       font-size: 30px;
//       width: 50px;
//       top: 0px;
//       color: rgba(0, 0, 0, 0.456);
//       font-weight: 200;

//       cursor: pointer;
//       .btn-clear {
//         border: none;
//         background-color: white;
//         img {
//           color: rgba(0, 0, 0, 0.53);
//           position: absolute;
//           right: 0px;
//         }
//       }
//     }

//     .btn_search {
//       position: absolute;
//       right: 0px;
//       width: 50px;
//       height: 40px;
//       text-align: center;
//       border: none;
//       cursor: pointer;
//       background: rgb(3, 194, 3);

//       i {
//         font-size: 20px;
//         color: $color_white;
//       }
//       &:hover {
//         background: rgba(157, 240, 34, 0.632);
//       }
//     }
//   }
// }

// .search-result {
//   position: absolute;
//   bottom: -210px;
//   z-index: 2;
//   height: 200px;
//   width: 390px;
//   background-color: white;
//   border-radius: 3px;
//   color: rgba(0, 0, 0, 0.802);
//   overflow-y: auto;
//   box-shadow: rgba(90, 102, 124, 0.2) 0px 2px 10px 0px;

//   .result {
//     height: 60px;
//     padding: 10px;
//     display: flex;
//     border-bottom: solid rgba(128, 128, 128, 0.142) 1px;
//     border-width: 80%;

//     .img {
//       position: absolute;
//       left: 0px;
//       width: 40px;
//       height: 40px;

//       i {
//         position: absolute;
//         left: 20%;
//         top: 20%;
//         font-size: 25px;
//         color: rgba(59, 59, 59, 0.224);
//       }
//     }

//     .info {
//       position: absolute;
//       left: 60px;
//       height: 40px;
//       text-align: left;
//       color: rgb(132, 142, 156);
//       font-family: ProximaNova-Regular;
//       font-size: 12px;
//       cursor: pointer;

//       #block {
//         width: 50px;
//       }
//     }
//   }
// }

.search-box_out-side {
  width: 470px;
  // padding-top: 20px;
  [tabindex="-1"]:focus {
    outline: none !important;
  }
  & input {
    background: rgba(4, 114, 142, 0.2);
    color: #fff;
    font-size: 12px;
    text-overflow: ellipsis;
    ::placeholder {
      color: red;
    }
  }
  .anticon {
    // display: inline-block;
    color: inherit;
    // font-style: normal;
    // line-height: 0;
    // text-align: center;
    // text-transform: none;
    // vertical-align: -0.125em;
    // text-rendering: optimizeLegibility;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
  }
  // .anticon > * {
  //   line-height: 1;
  // }
  // .anticon svg {
  //   display: inline-block;
  // }
  // .anticon::before {
  //   display: none;
  // }
  // .anticon .anticon-icon {
  //   display: block;
  // }
  // .anticon[tabindex] {
  //   cursor: pointer;
  // }
  // @-webkit-keyframes loadingCircle {
  //   100% {
  //     -webkit-transform: rotate(360deg);
  //     transform: rotate(360deg);
  //   }
  // }
  // @keyframes loadingCircle {
  //   100% {
  //     -webkit-transform: rotate(360deg);
  //     transform: rotate(360deg);
  //   }
  // }
  // .ant-btn .anticon {
  //   -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  //   transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  // }
  // .ant-btn .anticon.anticon-plus > svg,
  // .ant-btn .anticon.anticon-minus > svg {
  //   shape-rendering: optimizeSpeed;
  // }
  // .ant-btn.ant-btn-loading {
  //   position: relative;
  //   pointer-events: none;
  // }
  // .ant-btn.ant-btn-loading::before {
  //   display: block;
  // }
  // .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  //   padding-left: 29px;
  // }
  // .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only)
  //   .anticon:not(:last-child) {
  //   margin-left: -14px;
  // }
  // .ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
  //   display: block;
  //   color: #1890ff;
  //   text-align: center;
  // }
  // .ant-upload-list-item-info .anticon-loading,
  // .ant-upload-list-item-info .anticon-paper-clip {
  //   position: absolute;
  //   top: 5px;
  //   color: rgba(0, 0, 0, 0.45);
  //   font-size: 14px;
  // }
  // .ant-input-clear-icon {
  //   font-size: 12px;
  //   vertical-align: top;
  //   cursor: pointer;
  //   -webkit-transition: color 0.3s;
  //   transition: color 0.3s;
  // }
  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 45px;
  }
  .ant-input:hover {
    border-color: #f0b90b;
    border-right-width: 1px !important;
  }
  .ant-input:focus {
    border-color: #f0b90b;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #f0b90b;
  }
}
