// color

$color_blue: #15b5dd;
$color_blue_link: #4a90e2;
$color_blue_lagoon: #04728e;
$color_white: #ffffff;
$color_white_rgb: #fdfdfd;
$color_primary: #212833;
$color_secondary: #848e9c;
$color_grey_athens: #ebedef;
$color_grey_river: #48515d;
$color_grey_light: #f9f9f9;
$color_grey_alto: #d8d8d8;
$color_green: #00c087;
$color_red: #e03a3e;
// font-family

$font_root: ProximaNova-Regular, -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;

// font size
$font_size_base: 14px;
$font_size_small: 12px;
$font_size_medium: 16px;
$font_size_above_medium: 20px;
$font_size_large: 28px;

$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-ultra-bold: 900;
