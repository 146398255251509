.container-call-contract {
  position: relative;
  width: 100%;
  height: 650px; //100vh;
  .side-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 25%;
    min-width: 300px;
    .rc-tabs-tab {
      padding: 18px;
      margin-right: 10px;
    }
    .rc-tabs-top {
      border: none;
    }
    .rc-tabs-nav-container {
      font-size: 14px;
    }
    .wrapper-funcs {
      height: 585px;
      overflow-x: hidden;
      ul {
        padding: 1px;
        // overflow-x: hidden;
      }
      li {
        display: flex;
        justify-content: space-between;
        margin: 10px;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        // margin-bottom: 20px;
        align-items: center;
        // position: relative;
        // box-sizing: border-box;
        color: rgb(38, 49, 71);
        font-weight: 400;
        border-radius: 3px;
        // border-left: 4px solid rgb(255, 255, 255);
        // border-left: 4px solidf #eee;
        // box-shadow: rgba(223, 226, 231, 0.5) 0px 0px 5px 0px;
        background: #eee;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      li:hover {
        font-weight: 600;
        box-shadow: rgba(223, 226, 231, 0.5) 0px 0px 5px 0px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        // background: rgb(255, 255, 255);
        background: #f5f2f0;
        border-left: 4px solid rgb(21, 181, 221);
      }
      .on {
        box-shadow: rgba(223, 226, 231, 0.5) 0px 0px 5px 0px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        font-weight: 600;
        // background: rgb(255, 255, 255);
        background: #f5f2f0;
        border-left: 4px solid rgb(21, 181, 221);
      }
      .typeFunc {
        font-size: 0.9em;
        width: 50%;
        text-align: center;
      }
    }
  }
  .dragbar {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    z-index: 999;
    border-right: 1px solid #f3f3f3;
  }

  .side-main {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 74%;
    min-width: 800px;
    overflow: hidden;
    .contrainer-main {
      overflow-x: hidden;
      padding: 10px 50px;
      // width: 100%;
      height: 100%;
      .connector-wallet {
        padding: 10px 0 20px;
        #connector {
          font-size: 12px;
          color: #28a745 !important;
        }
        & > span {
          margin-right: 15px;
        }
      }
      .wrapper-func {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 5px;
        margin-bottom: 1rem !important;
      }
      .info-box {
        line-height: 1;
        font-size: 90%;
        background: #f5f2f0;
        padding: 5px 12px;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      }
      .active,
      .info-box:hover {
        text-decoration: underline;
      }
      .func-content {
        padding: 0 18px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        background-color: #f1f1f1;
      }
      .func-content-shown-by-default {
        max-height: 100%;
        padding: 0 18px;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        background-color: #f1f1f1;
      }
      .info-box:after {
        content: '\2193'; /* Unicode character for close */
        font-size: 20px;
        // color: #15b5dd;
        float: right;
        margin-left: 5px;
        // font-weight: bold;
      }

      .active:after {
        content: '\2192'; /* Unicode character for open */
        font-size: 20px;
        // color: #15b5dd;
        // font-weight: bold;
      }
      .func-body {
        padding: 1.25rem;
        color: #212529;
        .ant-input-affix-wrapper {
          margin-bottom: 1rem;
        }
        .ant-btn {
          margin-right: 2rem;
        }
        & input,
        & textarea {
          min-width: 100%;
        }
        & textarea {
          margin-bottom: 1rem;
        }
        & label {
          display: inline-block;
          margin-bottom: 0.5rem;
        }
        .myanswer {
          display: block;
          margin-top: 20px;
          line-height: 1.5;
          & pre {
            font-size: 12px;
          }
          .text-success {
            color: #28a745 !important;
            & i {
              font-weight: 700;
            }
          }
          .error {
            color: #dc3545;
          }
        }
      }
    }
  }
}
